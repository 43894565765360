import React from "react"
import ReactGA from "react-ga"
import MessengerCustomerChat from "react-messenger-customer-chat"
import { PhotoSwipeGallery } from "react-photoswipe"
// import { PhotoSwipe } from "react-photoswipe"
import "react-photoswipe/lib/photoswipe.css"
import "../stylesheets/custom.css"
import "github-fork-ribbon-css/gh-fork-ribbon.css"

import logo from "../images//logo.png"
import platform from "../images/platform.png"
import parallaxBackground from "../images/parallax-bg.jpg" // ko
import screen01 from "../images/01.png"
import screen02 from "../images/02.png"
import screen03 from "../images/03.png"
import screen04 from "../images/04.png"
import windowsdefendersmartscreen from "../images/windowsdefendersmartscreen.png"

const downloadLink =
  "https://github.com/jamlabs-net/graffiti/releases/download/v0.0.2/graffiti-setup-0.0.2.exe"
const version = "v0.0.2"

let items = [
  {
    src: screen01,
    thumbnail: screen01,
    w: 986,
    h: 668,
    title: "Choose the folders containing your videos",
  },
  {
    src: screen02,
    thumbnail: screen02,
    w: 986,
    h: 668,
    title: "All your videos are shown, click and see more details",
  },
  {
    src: screen03,
    thumbnail: screen03,
    w: 986,
    h: 668,
    title: "Tag your video and find easily find them through search",
  },
  {
    src: screen04,
    thumbnail: screen04,
    w: 986,
    h: 668,
    title: "Find all videos that a specific person/actor is in",
  },
]

let options = {
  //http://photoswipe.com/documentation/options.html
}
// let isOpen = false

const handleClose = () => {
  window.location.hash = "#screenshots"
}

const getThumbnailContent = item => {
  return (
    <img src={item.thumbnail} width={255} alt={item.title} title={item.title} />
  )
}

export default class All extends React.Component {
  componentDidMount = () => {
    ReactGA.initialize("UA-117105247-9")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <>
        <section className="hero">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12">
                <a className="hero-brand" href="/" title="Home">
                  <img alt="Logo" src={logo} />
                </a>
              </div>
            </div>

            <div className="col-md-12">
              <h1>Video library manager</h1>

              <p className="tagline" />
              <a className="btn btn-full btn-main" href={downloadLink}>
                Download for Windows {version}
              </a>
            </div>
          </div>
        </section>
        {/* <!-- /Hero --> */}

        {/* <!-- Header --> */}
        <header id="header">
          <div className="container">
            <div id="logo" className="pull-left">
              <a href="index.html">
                <img src={logo} alt="" title="" />
              </a>
            </div>

            <nav id="nav-menu-container">
              <ul className="nav-menu">
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  <a href="#screenshots">Screenshots</a>
                </li>
                <li>
                  <a href="#faq">FAQ</a>
                </li>
              </ul>
            </nav>
            {/* <!-- #nav-menu-container --> */}

            <nav className="nav social-nav pull-right d-none d-lg-inline">
              <a
                href="https://twitter.com/graffitijamlabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter" />
              </a>
            </nav>
          </div>
        </header>
        {/* //   <!-- #header --> */}

        {/* //   <!-- About --> */}

        <section className="about" id="about">
          <div className="container text-center">
            <h2>GRAFFITI IS A VIDEO LIBRARY MANAGER.</h2>

            <p>
              Graffiti is an app that lets you manage and keep all your videos
              in one place.
              <br />
              Tag your videos with keywords and people and quickly find the
              video you are looking for.
            </p>
            <iframe
              title="graffiti"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/h76XH1wSvso"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </section>
        {/* <!-- /About --> */}
        {/* <!-- Parallax --> */}

        <div
          className="block bg-primary block-pd-lg block-bg-overlay text-center platform"
          data-bg-img={parallaxBackground}
          data-settings='{"stellar-background-ratio": 0.6}'
          data-toggle="parallax-bg"
        >
          <h2>Windows today, Mac OS tomorrow...</h2>

          <p>
            For now, the only supported platform is Windows. But Mac OS will be
            supported very soon. Stay tuned and follow us on Twitter{" "}
            <a
              href="https://twitter.com/graffitijamlabs"
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              @graffitijamlabs
            </a>{" "}
            to not miss it!
          </p>
          <img
            alt="Video library manager for windows and mac"
            className="gadgets-img hidden-md-down"
            src={platform}
          />
        </div>
        {/* <!-- /Parallax --> */}
        {/* <!-- Features --> */}

        <section className="features" id="features">
          <div className="container">
            <h2 className="text-center">Features</h2>

            <div className="row">
              <div className="feature-col col-lg-4 col-xs-12">
                <div className="card card-block text-center">
                  <div>
                    <div className="feature-icon">
                      <span className="fa fa-film" />
                    </div>
                  </div>

                  <div>
                    <h3>All-in-one place</h3>

                    <p>
                      Videos accross multiple drives or folders are easily
                      accesible through the app.
                    </p>
                  </div>
                </div>
              </div>

              <div className="feature-col col-lg-4 col-xs-12">
                <div className="card card-block text-center">
                  <div>
                    <div className="feature-icon">
                      <span className="fa fa-tags" />
                    </div>
                  </div>

                  <div>
                    <h3>Tag them</h3>

                    <p>Tag your videos to quickly find them later</p>
                  </div>
                </div>
              </div>

              <div className="feature-col col-lg-4 col-xs-12">
                <div className="card card-block text-center">
                  <div>
                    <div className="feature-icon">
                      <span className="fa fa-users" />
                    </div>
                  </div>

                  <div>
                    <h3>People and actors</h3>

                    <p>
                      Identify people in the video to easily find all videos of
                      a particular individual.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- /Features --> */}
        <section
          className="features"
          id="screenshots"
          style={{ paddingTop: 0 }}
        >
          <div className="container">
            <h2 className="text-center">Screenshots</h2>
            {/* <div className="row">
          <a
            href={screen01}
            data-toggle="lightbox"
            data-gallery="example-gallery"
            className="col-sm-3"
            title="Choose the folders containing your videos"
          >
            <img
              src={screen01}
              className="img-fluid"
              alt="Choose the folders containing your videos"
            />
          </a>
          <a
            href={screen02}
            data-toggle="lightbox"
            data-gallery="example-gallery"
            className="col-sm-3"
            title="All your videos are shown, click and see more details"
          >
            <img
              src={screen02}
              className="img-fluid"
              alt="All your videos are shown, click and see more details"
            />
          </a>
          <a
            href={screen03}
            data-toggle="lightbox"
            data-gallery="example-gallery"
            className="col-sm-3"
            title="Tag your video"
          >
            <img
              src={screen03}
              className="img-fluid"
              alt="Tag your video and find easily find them through search"
            />
          </a>
          <a
            href={screen04}
            data-toggle="lightbox"
            data-gallery="example-gallery"
            className="col-sm-3"
            title="Find all videos that a specific person/actor is in"
          >
            <img
              src={screen04}
              className="img-fluid"
              alt="Find all videos that a specific person/actor is in"
            />
          </a>
        </div> */}
            <div className="row">
              <PhotoSwipeGallery
                items={items}
                options={options}
                onClose={handleClose}
                thumbnailContent={getThumbnailContent}
              />
            </div>
          </div>
        </section>

        {/* <!-- Call to Action --> */}

        <section className="cta">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-sm-12 text-lg-left text-center">
                <h2>Try it out, it's free!</h2>

                <p>
                  The app is under active developement. There' more features to
                  come and the app will auto update with each new version. Let
                  us know what features you'd like to see on Twitter{" "}
                  <a
                    href="https://twitter.com/graffitijamlabs"
                    style={{ color: "white" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @graffitijamlabs
                  </a>
                  !
                </p>
              </div>

              <div className="col-lg-3 col-sm-12 text-lg-right text-center">
                <a className="btn btn-ghost" href={downloadLink}>
                  Download {version}
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- /Call to Action --> */}
        {/* <!-- Team --> */}

        <section className="team" id="faq">
          <div className="container">
            <h2 className="text-center">Frequently Asked Questions</h2>

            <div id="accordion">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <a
                      href="/#"
                      className="collapsed"
                      style={{ cursor: "pointer" }}
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Where is the Mac OS / Linux version?
                    </a>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    At the moment, only the Windows version is available to the
                    public. The Mac OS and Linux version will be available soon.
                    Follow us on Twitter{" "}
                    <a
                      href="https://twitter.com/graffitijamlabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @graffitijamlabs
                    </a>{" "}
                    and we'll keep you posted!
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <a
                      href="/#"
                      className="collapsed"
                      style={{ cursor: `pointer` }}
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      After downloading the installer, Windows Defender
                      SmartScreen shows up. How do I install the app?
                    </a>
                  </h5>
                </div>
                {/* <!-- add className collapse to hide --> */}
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    First of all don't worry about the Windows warning, that
                    usually happens with new published apps. The installer is
                    perfectly safe. To proceed with the install, click on{" "}
                    <strong>More info</strong> and <b>Run anyway</b>.
                    <br />
                    <img
                      src={windowsdefendersmartscreen}
                      alt="Windows Defender SmartScreen"
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <a
                      href="/#"
                      className="collapsed"
                      style={{ cursor: "pointer" }}
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How can I contact you?
                    </a>
                  </h5>
                </div>
                {/* <!-- add className collapse to hide --> */}
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    On Twitter{" "}
                    <a
                      href="https://twitter.com/graffitijamlabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @graffitijamlabs
                    </a>
                    !
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- /Team --> */}
        {/* <!-- @component: footer --> */}
        <MessengerCustomerChat
          pageId="265125234041560"
          appId="0"
          themeColor="#4AB8A1"
        />
        <footer className="site-footer">
          <div className="bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xs-12 text-lg-left text-center">
                  <p className="copyright-text">
                    {/* © */} 2018 - {new Date().getFullYear()}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a className="scrolltop" href="/#">
          <span className="fa fa-angle-up" />
        </a>
      </>
    )
  }
}
