import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import All from "../components/all"

import "../stylesheets/style.css"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Graffiti | Video manager"
      keywords={[`graffiti`, `application`, `video manager`]}
    />
    <All />
  </Layout>
)

export default IndexPage
